<template>
    <div class="detentionWarning-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">校车管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">预警记录</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="学生姓名" prop="name">
                <el-input v-model="queryModel.name" size="mini"/>
            </el-form-item>
            <el-form-item label="预警车辆" prop="licensePlateNumber">
                <el-input v-model="queryModel.licensePlateNumber" size="mini"/>
            </el-form-item>
            <el-form-item label="触发时间" prop="happenTimeRanges">
                <el-date-picker
                size="mini"
                v-model="queryModel.happenTimeRanges"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="所属学校" prop="schoolId">
                <el-select v-model="queryModel.schoolId" placeholder="请选择" size="mini">
                <el-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
            
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-download"
            :loading="downloadLoading"
            @click="downloadXls"
        >导出数据</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="warningId" label="预警编号" fixed="left"></el-table-column>
            <el-table-column prop="name" label="学生姓名" fixed="left"></el-table-column>
            <el-table-column prop="happenTime" label="触发时间" fixed="left"></el-table-column>
            <el-table-column prop="licensePlateNumber" label="预警车辆" fixed="left"></el-table-column>
            <el-table-column prop="location" label="位置" fixed="left">
                <template slot-scope="{row}">
                    <el-link type="primary" @click="handleLocation(row)" :underline="false">查看地图</el-link>
                </template> 
            </el-table-column>
            <el-table-column prop="enabled" label="预警状态" fixed="left">
                <template slot-scope="{row}">
                    <span v-if="row.enabled" style="color:red">未解除</span>
                    <span v-else style="color:green">已解除</span>
                </template>
            </el-table-column>
            <el-table-column prop="enabledBy" label="解除人员" fixed="left"></el-table-column>
            <el-table-column prop="enabledTime" label="解除时间" fixed="left"></el-table-column>
            <el-table-column prop="driverName" label="司机" fixed="left">
                <template slot-scope="{row}">
                    <div>{{row.driverName}}</div>
                    <div>{{row.driverPhone}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="lifeguardName" label="照看员" fixed="left">
                <template slot-scope="{row}">
                    <div>{{row.lifeguardName}}</div>
                    <div>{{row.lifeguardPhone}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="schoolName" label="所属学校" fixed="left"></el-table-column>
            <el-table-column prop="className" label="班级" fixed="left"></el-table-column>
            <el-table-column prop="classTeacherName" label="班主任" fixed="left">
                <template slot-scope="{row}">
                    <div>{{row.classTeacherName}}</div>
                    <div>{{row.classTeacherPhone}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="contactPhone" label="联系电话" fixed="left"></el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <el-dialog
            :visible.sync="showMapDialog"
            title="地图位置"
            :modal-append-to-body="true"
            :append-to-body="true"
            style="text-align:left;"
            :close-on-click-modal="false"
            >
            <el-amap
                ref="map"
                vid="amapDemo"
                :amap-manager="amapManager"
                :center="pointPosition"
                :zoom="zoom"
                :events="events"
                class="amap-demo"
            >
                <el-amap-marker vid="amapDemo" :position="pointPosition"></el-amap-marker>
            </el-amap>
        </el-dialog>
    </div>
</template>
<script>
import detentionWarningApi from '@/api/bus/detentionWarning'
import AMap from "vue-amap";
let amapManager = new AMap.AMapManager();
export default {
    name:"busDetentionWarningList",
    data(){
        return{
            queryModel:{
                name:"",
                licensePlateNumber:"",
                schoolId:"",
                happenTimeRanges:"",
            },
            schoolList:[],
            loading: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            showModal: false,
            showMapDialog: false,
            pointPosition: [112.240222, 30.337053],
            amapManager,
            zoom: 12,
            downloadLoading: false,
            events: {
                click: e => {
                var pt = e.lnglat; //点击选择新地址为中心点
                console.log(pt);

                this.pointPosition = [pt.lng, pt.lat];
                }
            },
        }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("name", this.queryModel.name);
            formData.append("licensePlateNumber", this.queryModel.licensePlateNumber);
            formData.append("happenTimeRanges", this.queryModel.happenTimeRanges);
            formData.append("schoolId", this.queryModel.schoolId);



            detentionWarningApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handleLocation(row){
            this.selectedRow = row;
            this.showMapDialog = true;

            if (row.location != null && row.location.length > 0) {
                var arr = row.location.split(",");

                this.pointPosition = arr;
            }
        },
        downloadXls() {
            //导出
            var self = this;
            self.downloadLoading = true;
            self.loading = true;
            self.loadingText = "数据导出中，请稍后...";

            var formData = new FormData();

            formData.append("name", this.queryModel.name);
            formData.append("licensePlateNumber", this.queryModel.licensePlateNumber);
            formData.append("happenTimeRanges", this.queryModel.happenTimeRanges);
            formData.append("schoolId", this.queryModel.schoolId);

            var timestamp = new Date().getTime();
            formData.append("timestamp", timestamp);

            formData.append("pageSize", 2000);

            detentionWarningApi.exportXls(formData).then(function(response) {
                var jsonData = response.data;
                self.downloadLoading = false;
                self.loading = false;
                if (jsonData.result) {
                //下载有错误信息提示的报表
                self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                    duration: 60000,
                    type: "success"
                });
                } else {
                self.$message.error(jsonData.message);
                }
            });
        },
    },
    created() {
        var self = this;

        detentionWarningApi.schoolList().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });
    },
    mounted() {
        this.changePage(1);
    },
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
.detentionWarning-list{
    text-align: left;
}
.amap-demo {
  width: 100%;
  height: 400px;
}

</style>
